import React from 'react'

// components
import SEO from '../components/SEO/seo'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'

class TermsPage extends React.Component {

    render(){
        return <>
            <SEO title='Terms'/>
            <Layout>
                <Section title={'Terms of Service'}>
                    <div className='col-12 col-md-8'>
                        <p>
                            <strong>IMPORTANT:</strong>&nbsp;
                  The following Terms and Conditions (<strong>Terms of Use</strong>) are a
                  legal agreement between you, being an user of the Website, Web-Portal or
                  the Product (referred to as <strong>you</strong> or <strong>your</strong>)
                  and RapidParrot Limited, a company incorporated in New Zealand (referred to as <strong>us, we,</strong> or    <strong> our</strong>), in respect of your access to and use of the
                  Website, Web-Portal or Product provided by us (as applicable).
                        </p>
                        <p>
                      By accessing or otherwise using our Website, Web-Portal or Product, you
                      acknowledge and agree to be bound by these Terms of Use.
                  If you do not agree to be bound by these Terms of Use, do not access or use
                  the Website, Web-Portal or the Product, and immediately notify us of this
                  decision.
                        </p>
                        <p>
                  1. <strong>GLOSSARY</strong>
                        </p>
                        <p>
                  1.1 In this Terms of Use, unless the context otherwise permits:
                        </p>
                        <p>
                            <strong>End User </strong>
                  means, as the context permits, teachers, employees, contractors, agents,
                  and/or students of a Licensee, that have registered for a Login with
                  QuickSense;
                        </p>
                        <p>
                  (a) <strong>Licence</strong> means a non-transferrable, non-exclusive
                  licence granted by us to a Licensee under a product license agreement,
                  which allows End Users of the Licensee to access and use the Product during
                  the term of that product license agreement;
                        </p>
                        <p>
                  (b) <strong>Licensee</strong> means a party to a product license agreement
                  with QuickSense Holdings Limited under which the<strong> </strong>Licensee
                  is granted a Licence;
                        </p>
                        <p>
                  (c) <strong>Login</strong> means each End User’s individualised secure
                  login to the Web-Portal, issued to the End User by QuickSense, and
                  associated with the End User’s School Email;
                        </p>
                        <p>
                  (d) <strong>Product </strong>means the online testing and analytics
                  platform developed by us, accessible only via a Login;
                        </p>
                        <p>
                  (e) <strong>School Email </strong>means the individualised, secure emails
                  issued to each End User by a Licensee;<strong></strong>
                        </p>
                        <p>
                  (f) <strong>Terms of Use </strong>means these Terms of Use (as amended from
                  time to time) accessible from the Website;
                        </p>
                        <p>
                  (g) <strong>User Data</strong> means all data entered, captured or created
                  relating to use of the Website, Web-Portal or Product, as a consequence of
                  using the Website, Web-Portal or the Product;
                        </p>
                        <p>
                  (h) <strong>Web-Portal</strong> means the internet-based access point and
                  internal platform where End Users can access and use the Product by
                  entering their Login; and
                        </p>
                        <p>
                  (i) <strong>Website </strong>means the public facing website developed and
                  created by QuickSense available at www.quicksense.org.
                        </p>
                        <p>
                  2. <strong>ACCESS AND ACCEPTANCE</strong>
                        </p>
                        <p>
                  2.1 By using the Website, Web-Portal or Product, you hereby agree to these
                  Terms of Use and agree and acknowledge that you are liable for any breach
                  of these Terms of Use.
                        </p>
                        <p>
                  2.2 If you are an End User, you acknowledge that your access to the Product
                  will last for the duration of the Licence granted to the Licensee with
                  which you are associated. When the term of that Licensee’s License expires,
                  your access to the Product will also cease.
                        </p>
                        <p>
                  3. <strong>PROVISION OF THE WEBSITE, WEB-PORTAL AND PRODUCT</strong>
                        </p>
                        <p>
                  3.1 You acknowledge and agree that the information contained and accessed
                  on or through the Website is provided by us for general guidance, and is
                  intended to offer you general information. The information provided is not
                  intended to be a substitute or replace any professional advice, consultancy
                  or service. <strong> </strong>
                        </p>
                        <p>
                  3.2 You acknowledge and agree that there is no guarantee that access to our
                  Website, Web-Portal or the Product will be without disruptions, delays or
                  communication-related flaws. We are not liable for any such disruptions,
                  delays or other omissions in any communication experienced when using our
                  Website, Web-Portal or the Product.
                        </p>
                        <p>
                  3.3 We will use reasonable endeavours to notify you before deliberately
                  interrupting or suspending the Website, Web-Portal or Product in connection
                  with the implementation of any major update.
                        </p>
                        <p>
                  3.4 You agree that we may:
                        </p>
                        <p>
                  (a) temporarily suspend or restrict access to the Website, Web-Portal or
                  Product to carry out maintenance, upgrades, repairs and/or make
                  improvements to the Website, Web-Portal or Product; and
                        </p>
                        <p>
                  (b) change any technical and/or other features of the Website, Web-Portal
                  or Product in order to keep pace with the latest demands and technological
                  developments or to comply with any regulations.
                        </p>
                        <p>
                  3.5 We may change these Terms of Use or the way in which we provide the
                  Website, Web-Portal or Product, at any time. If we do:
                        </p>
                        <p>
                  (a) we will seek to give you reasonable notice of the change;
                        </p>
                        <p>
                  (b) the change will take effect when we post it on the Website, or notify
                  you the next time that you access the Product; and
                        </p>
                        <p>
                  (c) you will be bound by the change immediately upon it being posted on the
                  Website, or (if applicable) when you are notified of the change when
                  accessing the Product.
                        </p>
                        <p>
                  4. <strong>YOUR USE</strong>
                        </p>
                        <p>
                  4.1 In using the Website, Web-Portal or Product, you agree that you will:
                        </p>
                        <p>
                  (a) not access, input or upload any contribution or content to the Website
                  or Web-Portal without our consent;
                        </p>
                        <p>
                  (b) agree to abide by these Terms of Use and the <a href='privacy'>Privacy Policy</a>
                        </p>
                        <p>
                  (c) promptly respond to all reasonable requests made by us for instruction
                  and information, and promptly do all things reasonably necessary to enable
                  us to fully and effectively provide the Website, Web-Portal or the Product;
                        </p>
                        <p>
                  (d) use the Website, Web-Portal and the Product properly and for its
                  intended purpose, in accordance with these Terms of Use and any written
                  instructions which may be received from us from time to time;
                        </p>
                        <p>
                  (e) only use the Website, Web-Portal and the Product in accordance with
                  industry best practices for internet protocol;
                        </p>
                        <p>
                  (f) you may not publish hyperlinks to the Website, Web-Portal or the
                  Product without our written consent;
                        </p>
                        <p>
                  (g) ensure that the Website, Web-Portal or the Product are not used for the
                  sending of unsolicited emails;
                        </p>
                        <p>
                  (h) not make available, upload or distribute by any means any material or
                  files that contain any viruses, bugs, corrupt data, “Trojan horses”,
                  “worms” or any other harmful software to or from the Website, Web-Portal or
                  the Product;
                        </p>
                        <p>
                  (i) keep your Login secure, and not your Login share with any third
                  parties;
                        </p>
                        <p>
                  (j) not use the Website, Web-Portal and the Product for the purposes of
                  data mining, extraction, collection, emulating, phreaking, hacking,
                  password cracking, IP spoofing or overloading the Website, Web-Portal or
                  Product, “framing”, inserting pop-up windows, interstitial pages,
                  advertisements, performing any automated operation or any such other
                  similar actions; and
                        </p>
                        <p>
                  (k) not use or allow anyone else to use the Website, Web-Portal and the
                  Product to directly or indirectly input content which is or may be
                  malicious or defamatory, software which assists in or promotes emulators,
                  phreaking, hacking, password cracking, IP spoofing, be illegal, obscene,
                  offensive, threatening, violent, sexually explicit, pornographic, promote
                  discrimination or animosity to any person on grounds of gender, race,
                  religion, nationality, disability, sexual orientation, age, is likely to
                  harass, intimidate, bully, upset, embarrass, alarm, annoy any other person,
                  is likely to deceive any person or be used to impersonate any person, or to
                  misrepresent your identity, age or affiliation with any person, give the
                  impression that it emanates from us or that you are connected with us or
                  that we have endorsed you or your business, solicit passwords or personal
                  information from anyone, be used to send any communication by automated
                  email or otherwise, be incomplete or inaccurate or submitted otherwise than
                  as requested by the Website, Web-Portal or through the Product, request
                  personal information from other users nor post any unnecessary personal
                  information about you or any user without their permission or link to any
                  of the material specified above in this paragraph.
                        </p>
                        <p>
                  4.2 You acknowledge and agree that you:
                        </p>
                        <p>
                  (a) own or have rights to use all contributions that are input into the
                  Website, Web-Portal and the Product by you;
                        </p>
                        <p>
                  (b) have the authority or permission of any third party in respect of any
                  personal information provided to us (as applicable);
                        </p>
                        <p>
                  (c) are responsible and liable for any breach of intellectual property
                  rights, defamation, or any law, which may occur as a result of any
                  contributions by you when using the Website, Web-Portal and the Product;
                        </p>
                        <p>
                  (d) will immediately notify us of any security breach or unauthorised use
                  of your Login or School Email;
                        </p>
                        <p>
                  (e) where applicable, are responsible for determining what information
                  should be treated as shared input on the Website, Web-Portal and Product;
                  and
                        </p>
                        <p>
                  (f) irrevocably release us from any right or claim arising out of or in
                  connection with any contribution inputted by you.
                        </p>
                        <p>
                  5. <strong>INTELLECTUAL PROPERTY</strong>
                        </p>
                        <p>
                  5.1 You acknowledge that our intellectual property includes all statutory,
                  common law and proprietary rights of a party, including patents, designs,
                  plant varieties, trademarks, copyright works, layout designs, know-how and
                  other information, whether registered or unregistered (including
                  applications for such rights) as may exist anywhere in the world at any
                  time (<strong>Intellectual Property</strong>) in relation to the Website,
                  Web-Portal and the Product.
                        </p>
                        <p>
                  5.2 We retain sole and exclusive ownership (including all the Intellectual
                  Property rights) in the Website, Web-Portal and Product, whether in its
                  original form and/or as modified by any person.
                        </p>
                        <p>
                  5.3 You acknowledge and agree that:
                        </p>
                        <p>
                  (a) nothing in these Terms of Use intends to transfer any such Intellectual
                  Property rights to, or to vest any such Intellectual Property rights in
                  you; and
                        </p>
                        <p>
                  (b) you will not at any time directly or indirectly challenge or contest
                  ownership of our or any third party’s Intellectual Property rights who are
                  providing the Site or Service.
                        </p>
                        <p>
                  5.4 You acknowledge and agree that all title and intellectual property
                  rights in and any third party content that may be accessed through use of
                  the Website, Web-Portal and the Product is the property of the respective
                  content owners and may be protected by applicable copyright or other
                  Intellectual Property laws and treaties.
                        </p>
                        <p>
                  5.5 You acknowledge and agree that materials on the Website, Web-Portal and
                  provided through the Product may not be modified, reproduced, publicly
                  displayed or used for any public or commercial use without our express
                  permission in writing (including third-party links). If you do not obtain
                  our express written permission we bear no risk, responsibility or
                  liability.
                        </p>
                        <p>
                  6. <strong>DATA </strong>
                        </p>
                        <p>
              6.1 We will store personal information in accordance with the    <a href='privacy'>Privacy Policy</a>.<strong> </strong>We have reasonable security
                  policies and procedures in place to protect personal information from
                  unauthorised loss, misuse, alteration or destruction. Despite our best
                  effort, we cannot guarantee our security against all threats. We will use
                  all reasonable efforts to ensure that access to personal information is
                  limited to those who need to know.
                        </p>
                        <p>
                  6.2 We may use all data and contributions input by you in order to provide
                  and improve the Services, troubleshoot problems, prevent, detect and
                  investigate potentially prohibited or illegal activities, enforcement of
                  these Terms of Use, manage, customise and improve our services, content and
                  advertising, tell you about our services, provide you with targeted
                  marketing, service updates, and promotional offers preferences and compare
                  information for accuracy, and verify it with third parties.
                        </p>
                        <p>
                  7. <strong>LIABILITY</strong>
                        </p>
                        <p>
                  7.1 All representations and warranties in respect of the Product whether
                  statutory, express or implied are expressly excluded (except any which may
                  not be lawfully excluded), including, without limitation, the implied
                  warranties of merchantability and fitness for any particular purpose.
                        </p>
                        <p>
                  7.2 To the fullest extent permitted by law, in no event will we be liable
                  to you for any direct, indirect, incidental, special, economic or
                  consequential loss or damage or loss of revenue, profits, goodwill, bargain
                  or opportunities or loss of anticipated savings incurred or suffered by you
                  whether caused by negligence or otherwise or whether or not we were aware
                  or should have been aware of the possibility of such loss or damage.
                        </p>
                        <p>
                  7.3 To the fullest extent permitted by law, if we are held or found to be
                  liable to you for any matter, whether based on an action or claim in
                  contract, equity, negligence, tort, or under any indemnity or otherwise,
                  the amount of damages recoverable against us for all events, acts or
                  omissions will not in aggregate exceed one hundred New Zealand dollars
                  ($NZ100).<strong> </strong>
                        </p>
                        <p>
                  8. <strong>TERMINATION</strong>
                        </p>
                        <p>
                  8.1 We may immediately terminate your use of the Website, Web-Portal and
                  Product, if:
                        </p>
                        <p>
                  (a) you fail to comply with any obligation imposed on you under these
                  Terms; or
                        </p>
                        <p>
                  (b) we cease to provide the Product to the Licensee with which you are
                  associated for whatever reason.
                        </p>
                        <strong>
                        </strong>
                        <p align='left'>
                            <strong></strong>
                        </p>
                        <p>
                  9. <strong>GENERAL TERMS</strong>
                        </p>
                        <p>
                  9.1 No delay, grant of time, release, compromise, forbearance (whether
                  partial or otherwise) or other indulgences by a party in respect of any
                  breach of the other party’s obligations under these Terms is to:
                        </p>
                        <p>
                  (a) operate as a waiver or prevent the subsequent enforcement of that
                  obligation; or
                        </p>
                        <p>
                  (b) be deemed a delay, grant of time, release, compromise, forbearance
                  (whether partial or otherwise) or other indulgences in respect of, or a
                  waiver of, any subsequent or other breaches.
                        </p>
                        <p>
                  9.2 These Terms of Use are governed by and shall be construed in accordance
                  with the laws of New Zealand. The parties submit to the non-exclusive
                  jurisdiction of the courts of New Zealand.
                        </p>
                    </div>
                </Section>
            </Layout>
        </>
    }
}

export default TermsPage